exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-about-index-al-jsx": () => import("./../../../src/pages/about/index.al.jsx" /* webpackChunkName: "component---src-pages-about-index-al-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-index-mk-jsx": () => import("./../../../src/pages/about/index.mk.jsx" /* webpackChunkName: "component---src-pages-about-index-mk-jsx" */),
  "component---src-pages-index-al-jsx": () => import("./../../../src/pages/index.al.jsx" /* webpackChunkName: "component---src-pages-index-al-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-mk-jsx": () => import("./../../../src/pages/index.mk.jsx" /* webpackChunkName: "component---src-pages-index-mk-jsx" */),
  "component---src-pages-products-electric-bikes-index-jsx": () => import("./../../../src/pages/products/electric-bikes/index.jsx" /* webpackChunkName: "component---src-pages-products-electric-bikes-index-jsx" */),
  "component---src-pages-products-electric-bikes-index-mk-jsx": () => import("./../../../src/pages/products/electric-bikes/index.mk.jsx" /* webpackChunkName: "component---src-pages-products-electric-bikes-index-mk-jsx" */),
  "component---src-pages-products-ev-chargers-index-jsx": () => import("./../../../src/pages/products/ev-chargers/index.jsx" /* webpackChunkName: "component---src-pages-products-ev-chargers-index-jsx" */),
  "component---src-pages-products-ev-chargers-index-mk-jsx": () => import("./../../../src/pages/products/ev-chargers/index.mk.jsx" /* webpackChunkName: "component---src-pages-products-ev-chargers-index-mk-jsx" */),
  "component---src-pages-products-index-al-jsx": () => import("./../../../src/pages/products/index.al.jsx" /* webpackChunkName: "component---src-pages-products-index-al-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-products-index-mk-jsx": () => import("./../../../src/pages/products/index.mk.jsx" /* webpackChunkName: "component---src-pages-products-index-mk-jsx" */),
  "component---src-pages-products-smart-bench-index-jsx": () => import("./../../../src/pages/products/smart-bench/index.jsx" /* webpackChunkName: "component---src-pages-products-smart-bench-index-jsx" */),
  "component---src-pages-products-smart-bench-index-mk-jsx": () => import("./../../../src/pages/products/smart-bench/index.mk.jsx" /* webpackChunkName: "component---src-pages-products-smart-bench-index-mk-jsx" */),
  "component---src-pages-products-smart-bins-index-jsx": () => import("./../../../src/pages/products/smart-bins/index.jsx" /* webpackChunkName: "component---src-pages-products-smart-bins-index-jsx" */),
  "component---src-pages-products-smart-bins-index-mk-jsx": () => import("./../../../src/pages/products/smart-bins/index.mk.jsx" /* webpackChunkName: "component---src-pages-products-smart-bins-index-mk-jsx" */),
  "component---src-pages-products-smart-parking-index-jsx": () => import("./../../../src/pages/products/smart-parking/index.jsx" /* webpackChunkName: "component---src-pages-products-smart-parking-index-jsx" */),
  "component---src-pages-products-smart-parking-index-mk-jsx": () => import("./../../../src/pages/products/smart-parking/index.mk.jsx" /* webpackChunkName: "component---src-pages-products-smart-parking-index-mk-jsx" */),
  "component---src-pages-products-street-lights-index-jsx": () => import("./../../../src/pages/products/street-lights/index.jsx" /* webpackChunkName: "component---src-pages-products-street-lights-index-jsx" */),
  "component---src-pages-products-street-lights-index-mk-jsx": () => import("./../../../src/pages/products/street-lights/index.mk.jsx" /* webpackChunkName: "component---src-pages-products-street-lights-index-mk-jsx" */),
  "component---src-pages-solution-index-jsx": () => import("./../../../src/pages/solution/index.jsx" /* webpackChunkName: "component---src-pages-solution-index-jsx" */),
  "component---src-pages-solution-index-mk-jsx": () => import("./../../../src/pages/solution/index.mk.jsx" /* webpackChunkName: "component---src-pages-solution-index-mk-jsx" */)
}

